.form {
  position: fixed;
  left: 100%;
  top: 0;
  bottom: 0;
  width: 500px;
  background-color: #ffffff;
  border-radius: 21px 0px 0px 21px;
  z-index: 1022;
  border: 1px solid black;
  padding: 10px;
  transition: 0.2s linear;
  overflow-y: scroll;
}

.form::-webkit-scrollbar {
  display: none;
}

.disable-scroll .backdrop-header {
  outline: 1px solid #4a4747 !important;
  display: block !important;
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 5, 0.7);
  z-index: 1;
}

.disable-scroll #navbar_top {
  background-color: rgba(5, 5, 5, 0.7);
}

.disable-scroll .b2b-nav-top {
  border-bottom: none !important;
}

.form.active {
  left: calc(100% - 500px);
  font-family: var(--font-family);
}

.form-back {
  position: absolute;
  left: 30px;
  top: 30px;
  cursor: pointer;
  width: 7%;
}

.form-close {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  width: 7%;
}

.form-top .form-img {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  font-size: 37px;
  font-weight: 275;
  line-height: 56px;
  color: var(--white);
  background-image: url('B2B/Assets/Images/B2BReg.png');
  border-radius: 20px 0 0 0;
  height: 250px;
  max-width: 480px;
  width: 100%;
  object-fit: cover;
  text-transform: uppercase;
}

.form-top .form-img > b {
  font-size: 26px;
  font-weight: 500;
}

.form-top .form-icon {
  position: relative;
  width: 95px;
  top: -47px;
  left: calc(50% - 47px);
  background: #d9d9d9;
  border-radius: 50%;
  padding: 5px;
}

.form-bottom {
  position: relative;
  top: -1%;
  padding: 0px 10%;
}

.form-heading {
  font-size: 14px;
  color: #787878;
  margin-bottom: 20px;
  font-family: var(--font-family);
}

@media (max-width: 786px) {
  .form {
    top: 100%;
    left: 0;
    right: 0;
    padding: 10px;
    border-radius: 6px 6px 0 0;
  }

  .form.active {
    top: 20%;
    width: 100%;
    left: 0;
    right: 0;
  }

  .form-top .form-img {
    background-image: url('B2B/Assets/Images/B2BReg.png');
    width: 100%;
    border-radius: 0;
    height: 116px;
    font-size: 26px;
    line-height: 48px;
  }

  .form-top .form-img > b {
    font-size: 20px;
    line-height: 28px;
  }

  .form-close {
    top: 20px;
    right: 20px;
    width: 5%;
  }

  .form-back {
    left: 20px;
    top: 20px;
    width: 5%;
  }

  .form-top .form-icon {
    padding: 3px;
    width: 60px;
    top: -30px;
    left: calc(50% - 30px);
  }

  .form-bottom {
    padding: 0 5%;
  }
}
