.orderProfileWrapper {
  display: flex;
  padding: 20px 28px;
  background: #f8f8f8 !important;
  border-radius: 5px;
  align-items: center;
}

.mypro-main-outlet-wrapper {
  margin-top: 43px;
}

.ProfileLine {
  border-top: 1px solid #b8b8b8;
  opacity: 1;
  margin: 9px 0 16px 0;
}

.ProfileLine-gnrl {
  border-top: 1px solid #787878;
  opacity: 1;
  width: 79%;
}

.pro-description-heading {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 50px auto;
}

.new-pswrd-pd {
  padding-right: 1rem;
}

.checkicon {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.description-heading {
  background-color: #efefef;
  color: #787878;
  font-size: 15px;
  padding: 10px;
  text-transform: uppercase;
  line-height: 24px;
  font-weight: 500;
  max-width: 165px;
  width: 100%;
}

.mob-line {
  width: 85%;
}

@media screen and (max-width: 991px) {
  .mypro-main-outlet-wrapper {
    margin-top: 10px;
    margin-bottom: 30px;
  }
}

.eror-cngpswrd {
  margin-top: 8px;
}

input[type='date'] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  -webkit-padding-end: 20px;
  min-height: 1.2em;
  color: var(--dark);
  opacity: 0;
  overflow: visible;
}

input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

input[type='date']::-moz-calendar-picker-indicator {
  opacity: 0;
}

.extra-input {
  position: absolute;
  right: 0px;
  top: 0px;
  pointer-events: none;
}

.profile-icon {
  position: absolute;
  padding: 8px;
}

.dataInputFocus {
  background-color: var(--white) !important;
  border: 1px solid var(--dark) !important;
}

.profileSectionWrapper {
  padding: 15px;
}

.profile-details {
  display: flex;
  justify-content: space-between;
  gap: 15px;
}

.profile-details > div {
  flex: 1;
  min-width: 0;
}

.details {
  font-size: 20px;
  font-family: var(--font-family);
  line-height: 1;
  width: 100%;
  position: relative;
}

.details .resend-chngpswrd {
  background: #0000;
  border: none;
  color: grey;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 15px;
  margin-left: auto;
  margin-top: 10px;
}

.pop-para {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
}

.mypro-wrap {
  height: fit-content;
  border: 0;
}

.mypro-wrap .profile-delete {
  margin-top: 20px;
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.profile-delete {
  position: relative;
}

.profile-delete h3 {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 500;
  font-family: var(--font-family);
  line-height: 11px;
  margin-bottom: 0px;
}

.profile-delete .accordion__button {
  padding: 20px;
}

.profile-delete .accordion__button:after {
  display: inline-block;
  content: url('assets/icons/caret-down-arrow.svg');
  position: absolute;
  right: 2px;
}

.profile-delete .accordion__button::after {
  margin-right: 14px;
  top: 22%;
  transform-origin: center;
  transition: transform 0.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.profile-delete .accordion__button[aria-expanded='true'] {
  padding-bottom: 0;
}

.profile-delete .accordion__button[aria-expanded='true']::after,
.profile-delete .accordion__button[aria-selected='true']::after {
  transform: rotate(180deg);
  float: right;
  top: 10px;
}

.profile-delete .accordion__panel {
  padding: 20px;
  padding-top: 0;
}

.mypro-wrap .ordersProfileWrapper {
  border: 1px solid rgba(0, 0, 0, 0.15);
}

.profile-contact .modal-header {
  border-bottom: 2px solid #787878;
  padding: 0;
  padding-bottom: 10px;
}

.profile-contact .modal-header button {
  background: none;
  border: none;
  display: flex;
}

.profile-contact .modal-content {
  padding: 20px;
  top: 100px;
}

.profile-contact .popMainContent {
  display: flex;
  font-size: 14px;
  font-weight: 500;
  justify-content: center;
  line-height: 22px;
  margin-bottom: 20px;
  text-align: center;
  width: 100%;
}

.profile-contact .modal-title {
  font-size: 18px;
  line-height: 18px;
  text-transform: uppercase;
}

.delete-acc {
  margin-top: 28px;
  cursor: pointer;
  color: var(--wine);
  font-size: 12px;
  line-height: 18px;
}

.dropdownFocus {
  background-color: var(--white);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
}

.verify-email-header .btn-close {
  background: url('../../assets/icons/modalCross.svg');
  background-repeat: no-repeat;
  border-radius: unset;
  opacity: unset;
  box-shadow: unset;
  position: relative;
  top: 7px;
}

.verify-email-header .btn-close:hover {
  opacity: 1;
}

.details input::placeholder {
  text-transform: none;
}

.details .customDropdown {
  z-index: 3;
  position: relative;
}

.details .customDropdown ul {
  width: 100%;
  position: absolute;
  border-radius: 0px 0px 6px 6px;
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding-left: unset;
}

.details .customDropdown ul li {
  font-size: 12px;
  padding: 8px 10px;
}

.details .customDropdown ul li:hover {
  background-color: #f5f5f5;
}

.pro-err {
  margin: 3px 0 16px 0;
  padding: 0 !important;
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .extra-input {
    top: 34px;
  }
}

.changemodalClose {
  background-color: transparent;
  border: none;
  position: absolute;
  top: 18px;
  right: 20px;
}

.save-btn {
  background-color: var(--white);
  color: var(--dark);
  width: 100%;
  max-width: 300px;
  height: 30px;
  margin: 30px auto;
  text-transform: uppercase;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  font-size: 10px;
  line-height: 18px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.save-btn:disabled {
  cursor: not-allowed;
  border-radius: 6px;
  border: 1px solid #d9d9d9;
  color: #787878;
  background: #d9d9d9;
}

.sendMobOTP-btn {
  background-color: var(--dark);
  color: var(--white);
  width: 100% !important;
  border-radius: 5px !important;
  margin: 25px auto 0px auto !important;
  padding: 15px !important;
  text-transform: uppercase !important;
  font-size: 16px;
  font-weight: 400;
}

.Resend-btn {
  background-color: var(--white) !important;
  color: var(--dark) !important;
  width: 100%;
  border-radius: 5px;
  margin: 20px auto 40px auto !important;
  padding: 15px;
  text-transform: uppercase !important;
  font-size: 16px !important;
  font-weight: 400;
  -webkit-appearance: none;
  border: 1px solid var(--dark);
}

.otp-time {
  margin-top: -24px;
  margin-left: 45px;
  color: red;
}

.profileName {
  text-transform: capitalize;
  font-size: 11px;
}

.shpCartDetailTxt {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.details input {
  background-color: #f8f8f8;
  border: 1px solid transparent;
  padding: 10px;
  border-radius: 6px;
  width: 100%;
  font-size: 14px;
  font-weight: 400;
  height: 40px;
  margin: 0;
}

.details input:focus-visible {
  outline: none;
  background-color: var(--white);
}

.dob-details {
  position: relative;
}

.calender-icon {
  position: absolute;
  z-index: 2;
  margin: 12px 6px;
  right: 0.3em;
}

.calender-icon-safari {
  pointer-events: none;
}

.pop-main-content-chng-email {
  margin-top: 5px !important;
}

.form-err {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 23px;
  padding-top: 8px;
}

.delAdd {
  cursor: pointer;
}

.mar-right {
  margin-right: 20px;
}

.twoFA .formInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.twoFA .loginCta {
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 30px;
  width: 100%;
  max-width: 260px;
  height: 30px;
}

.twoFA .loginCta:disabled {
  border-color: #d9d9d9;
  background: #d9d9d9;
  color: #787878;
}

/* eye icon on chagne password */

.iconDiv {
  position: relative;
}

.icon {
  position: absolute;
  right: 3%;
  top: 60%;
  cursor: pointer;
}

.iconDivs {
  position: relative;
}

.iconEye-1 {
  position: absolute;
  right: 8%;
  top: 60%;
  cursor: pointer;
}

.iconEye-2 {
  position: absolute;
  right: 5%;
  top: 60%;
  cursor: pointer;
}

.eyeIcon-new {
  position: absolute;
  right: 3%;
  top: 55%;
  cursor: pointer;
}

.eyeIcon-cnfm {
  position: absolute;
  right: 3%;
  top: 55%;
  cursor: pointer;
}

.chng-input {
  padding: 15px;
  border-radius: 10px;
  border: 1px solid var(--dark);
}

@media screen and (max-width: 967px) {
  .input[type='date']::-webkit-calendar-picker-indicator {
    width: 470px;
  }
}

@media screen and (max-width: 767px) {
  .cng_pwd {
    flex-direction: column;
  }

  .new-pswrd-pd {
    padding-right: 0;
  }

  .iconDivs {
    padding-right: 0px !important;
  }

  .iconEye-1 {
    right: 5%;
  }

  .eyeIcon-new {
    position: absolute;
    right: 3%;
    top: 55%;
    cursor: pointer;
  }

  .eyeIcon-cnfm {
    position: absolute;
    right: 3%;
    top: 55%;
    cursor: pointer;
  }
}

.paddPassBox {
  padding-right: 1rem;
}

@media screen and (max-width: 762px) {
  .paddPassBox {
    padding-right: 0px;
  }
}

.input-lable {
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  margin-top: 10px;
}

.my-modal {
  width: 40vw;
  /* Occupy the 90% of the screen width */
  max-width: 90vw;
}

.cng-pass-btn {
  background-color: var(--dark);
  color: var(--white);
  width: 50%;
  border-radius: 5px;
  margin: 40px auto 90px auto;
  padding: 15px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 400;
}

.cng-pass-btn:disabled {
  cursor: not-allowed;
  pointer-events: all !important;
  border: 1px solid var(--dark);
  opacity: 0.25;
}

.number-input {
  padding: 15px !important;
  text-transform: none !important;
}

.edit-checkbox-item {
  display: flex;
}

.Profile-Name {
  text-transform: capitalize;
}

.otpInput {
  font-size: 15px;
  text-align: center;
  background: linear-gradient(0deg, #f5f5f5, #f5f5f5);
  border: none !important;
  font-weight: 500;
  width: 40px;
  height: 40px;
  border-radius: 6px;
  -moz-appearance: textfield;
}

.otpInput::-webkit-inner-spin-button,
.otpInput::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.otpInput:focus-visible {
  outline-color: var(--wine);
}

.quid-clearDues-frame {
  width: 100%;
  min-height: 800px;
}

.otpInput:not([value='']),
.otpInput:not(:empty) {
  background: var(--wine);
  color: var(--white);
}

.otpInput:not([value=''])::selection {
  background-color: var(--wine);
}

.otp-input {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 12px;
}

.otp_verify form {
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
}

.otp_verify .otpWrapper {
  text-align: center;
}

.mobileChangePopContent p.text-danger.code-err {
  margin: 0 6px;
}

.sendOTP-btn {
  width: 100%;
  max-width: 260px;
  border-radius: 5px !important;
  text-transform: uppercase !important;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  margin-top: 30px;
  border-radius: 6px;
  height: 30px;
}

@media screen and (max-width: 540px) {
  .pro-description-heading {
    margin: 20px auto;
  }
}

@media screen and (max-width: 992px) {
  .calender-icon {
    right: 0.1em;
  }
}

@media screen and (max-width: 1150px) {
  .details input.otpInput {
    width: 40px !important;
  }
}

@media screen and (max-width: 1024px) {
  .details input.otpInput {
    width: 39px !important;
  }

  .checkImg {
    width: 18px;
  }

  .description-heading {
    background-color: #efefef;
    color: #787878;
    font-size: 15px;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
  }

  input[type='date'] {
    -webkit-appearance: none;
    min-height: 1.2em;
    color: #161111;
    background-color: rgb(255, 255, 255);
  }

  .profileSectionWrapper {
    padding: 15px;
  }

  .profile-details {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }

  .pop-para {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
  }

  .details input::placeholder {
    text-transform: none;
  }
}

@media screen and (max-width: 481px) {
  .ordersProfileWrapper {
    margin-left: 0;
  }

  .profile-details {
    flex-direction: column;
    padding: 0;
  }

  .save-btn {
    margin: 50px auto;
    width: 80%;
  }

  .profileName {
    text-transform: capitalize;
    font-size: 11px;
  }

  .popMainContent {
    margin-bottom: 10px !important;
  }

  .popMainContent b {
    max-width: 124px;
  }

  .mobileChangePopContent {
    width: 100%;
  }
}

@media screen and (max-width: 991px) {
  .profile-details {
    flex-direction: column;
    gap: 0px;
  }
}

@media screen and (max-width: 575px) {
  .mob-line {
    width: 100%;
  }
}

@media screen and (max-width: 928px) {
  .chng-input {
    padding: 15px;
    border-radius: 10px;
    border: 1px solid var(--dark);
  }

  .input-lable {
    font-family: var(--font-family);
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 20px;
    margin-top: 10px;
  }

  @media screen and (max-width: 765px) {
    .input-lable {
      font-size: 18px;
    }
  }

  .cng-pass-btn {
    background-color: var(--dark);
    color: var(--white);
    width: 50%;
    border-radius: 5px;
    margin: 40px auto 90px auto;
    padding: 15px;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
  }

  .number-input {
    padding: 15px !important;
    text-transform: none !important;
  }

  .inputName {
    text-transform: capitalize;
  }

  .edit-checkbox-item {
    display: flex;
  }

  .Profile-Name {
    text-transform: capitalize;
  }

  @media screen and (max-width: 1024px) {
    .mobileChangePopContent {
      width: 100% !important;
    }

    .checkImg {
      width: 18px;
    }
  }

  @media screen and (max-width: 481px) {
    .profileSectionWrapper {
      flex-direction: column;
    }

    .ordersProfileWrapper {
      margin-left: 0;
    }

    .profile-details {
      flex-direction: column;
      padding: 0;
    }

    .cng-pass-btn {
      width: 50%;
      padding: 8px;
      font-size: 14px;
    }

    .save-btn {
      margin: 50px auto;
      width: 80%;
    }
  }

  @media screen and (max-width: 991px) {
    .profile-details {
      flex-direction: column;
    }

    .chngpswrd-input {
      display: flex !important;
      flex-direction: column;
    }

    .my-modal {
      top: 54px;
      width: 60vw;
      /* Occupy the 90% of the screen width */
      max-width: 95vw;
    }
  }

  @media screen and (max-width: 575px) {
    .otp-time {
      font-size: small;
      margin-left: 20px;
      color: rgb(227, 9, 78);
    }

    .my-modal {
      width: 100vw;
      /* Occupy the 90% of the screen width */
      max-width: 95vw;
    }
  }

  @media screen and (max-width: 990px) {
    .details input.otpInput {
      width: 45px !important;
    }
  }

  @media screen and (max-width: 928px) {
  }

  .error-msg {
    font-size: 18px;
    margin-top: 10px;
  }
}

.error-msg {
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: #c03;
  margin-top: 8px;
  margin-bottom: unset;
}

@media screen and (max-width: 600px) {
  .otp_verify .text-validation {
    margin: 10px 108px;
  }
}

@media screen and (max-width: 480px) {
  .sendMobOTP-btn {
    margin: 12px auto 0px auto !important;
  }

  .ProfileLine-gnrl {
    width: 100%;
  }

  .save-btn {
    margin: 0 auto;
    font-size: 14px;
  }

  .otp_verify .otpInput {
    width: 40px !important;
    height: 40px !important;
    font-size: 16px;
  }
}

@media screen and (max-width: 426px) {
  .otp_verify .text-validation {
    margin: 10px 0 22px 0 !important;
  }

  .description-heading {
    font-size: 14px;
  }

  .ProfileLine {
    margin: 10px 0;
  }
}

@media screen and (max-width: 320px) {
  .details input.otpInput {
    width: 37px !important;
  }

  .login__form {
    padding: 20px;
  }

  .mobileChangePopContent {
    width: 100%;
  }

  .otpInput {
    width: 33px !important;
    height: 40px !important;
  }

  .otp_verify .otpInput {
    width: 28px !important;
    height: 40px !important;
  }

  .otp_verify .text-validation {
    margin: 10px 0 22px 0 !important;
  }
}

@media screen and (max-width: 480px) {
  .save-btn {
    margin-top: 35px;
  }

  .details {
    min-height: 59px;
  }

  .calender-icon {
    top: 34px;
  }

  .form-err {
    line-height: 12px;
  }

  .details-dob {
    margin-top: -33px;
  }

  .pro-err {
    margin: 5px 0 10px 0 !important;
  }
}

.ulTerm {
  margin-top: 7px !important;
}

@media screen and (min-width: 600px) {
  .ordersProfileWrapper h3 {
    line-height: 10px;
  }

  .mywishlist h3 {
    line-height: 22px;
  }

  .manageNotif h3 {
    line-height: 13.4px;
  }

  .loyaltypoint .sideback img {
    margin-top: -13px !important;
    margin-bottom: -11px !important;
  }

  .ordersProfileWrapper .mypro-wrap {
    padding-top: 20px !important;
  }

  .side-back {
    max-height: 11px;
  }
}

.ordersProfileWrapper {
  overflow: visible !important;
}

@media screen and (min-width: 481px) and (max-width: 990px) {
  .customDropdown {
    margin-bottom: 18px !important;
  }
}

/* NEW CSS */

.proDetWrapper {
  box-shadow: 0px 0px 8px 0px #00000014;
  padding: 20px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
}

.proDetWrapper h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
}

.proInputWrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.proLabels {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.proLabels span {
  font-weight: 300;
  line-height: 22px;
  color: #787878;
  margin-bottom: 4px;
}

.proLabels .inputIcon {
  position: absolute;
  left: 12px;
  top: 34px;
}

.proLabels .genderIcon {
  left: 12px;
  height: 100%;
}

/* .customDropdown ul {
  width: 100%;
  position: absolute;
  top: 66px;
  border-radius: 0px 0px 6px 6px;
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding-left: unset;
} */

.proLabels .genderDropDown {
  line-height: 21px;
  background-color: var(--white);
  border: 1px solid #f8f8f8;
  height: 40px;
  padding: 0 8px;
  color: var(--dark);
  border-radius: 6px;
}

.proLabels .genderDropDown.open {
  line-height: 21px;
  background-color: var(--white);
  border: 1px solid #f8f8f8;
  height: 40px;
  padding: 0 8px;
  color: var(--dark);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px 6px 0px 0px;
}

.proLabels .genderDropDown.close {
  background-color: #f8f8f8;
}

.proLabels input {
  line-height: 21px;
  background-color: #f8f8f8;
  border: 1px solid #f8f8f8;
  height: 40px;
  width: 100%;
  max-width: 100%;
  border-radius: 6px;
  padding: 0 36px;
  color: var(--dark);
}

.proLabels input[type='date'] {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: transparent;
  border: none;
}

.proLabels input:read-only {
  background-color: #f8f8f8 !important;
  border-color: #f8f8f8 !important;
  outline: none !important;
}

.proLabels input:focus-visible {
  outline: none;
  background-color: var(--white);
  border-color: var(--dark);
}

.proLabels .editIcon {
  position: absolute;
  right: 12px;
  top: 34px;
  cursor: pointer;
}

.proLabels .proErrors {
  font-size: 10px;
  line-height: 23px;
  color: #cc0033;
  margin-bottom: unset;
}

.proNudge {
  font-size: 10px;
  line-height: 15px;
  margin-bottom: 0;
  margin-top: 20px;
}

.proNudge b {
  font-weight: 400;
  color: #23b93c;
  text-decoration: underline;
}

.proDetWrapper .proSaveBtn {
  width: 100%;
  max-width: 300px;
  height: 30px;
  margin: 30px auto 0 auto;
  border-radius: 6px;
  font-size: 10px;
  line-height: 18px;
}

.proLabels .dropdownFocus {
  background-color: var(--white);
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.25);
  border-color: var(--white) !important;
}

.customDropdown {
  z-index: 3;
  position: relative;
}

.dropDownInput::after {
  position: absolute;
  top: 35px;
  content: url('assets/icons/caret-down-arrow.svg');
  right: 12px;
}

.customDropdown ul {
  width: 100%;
  position: absolute;
  top: 62px;
  border-radius: 0px 0px 6px 6px;
  background: var(--white);
  box-shadow: 0px 3px 4px 0px rgba(0, 0, 0, 0.25);
  list-style: none;
  padding-left: unset;
}

.customDropdown ul li {
  font-size: 12px;
  padding: 8px 10px;
}

.customDropdown ul li:hover {
  background-color: #f5f5f5;
}

.proLabels input[type='date']::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.proLabels input[type='date']::-moz-calendar-picker-indicator {
  opacity: 0;
}

@media screen and (max-width: 768px) {
  .proDetWrapper {
    padding: 16px;
    max-width: 100%;
  }

  .proInputWrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
}
