/* stylelint-disable-next-line import-notation */
@import url(./Styles/loadFonts.css);
@import url(./Styles/variables.css);
@import url(./Styles/global.css);

body.no-scroll {
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.terms-cond {
  font-family: var(--font-family);
}

.terms-cond .breadcrumb-item + .breadcrumb-item::before {
  content: '';
}

.breadcrumb_Wrapper.breadcrumbProfile .breadcrumb-item-profile {
  font-weight: 400;
  font-size: 10px;
}

.breadcrumb_Wrapper.breadcrumbProfile .breadcrumb-item-profile > span {
  text-transform: capitalize;
}

.cancellation-return {
  font-family: var(--font-family);
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  line-height: 22px !important;
  margin-bottom: 0;
}

.show-underline::after {
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background-color: #b8b8b8;
  margin-top: 15px;
}

.cancel-order-popup {
  overflow: visible !important;
}

.notify-view-modal-open {
  overflow: visible !important;
}

li.parent.no-hover:hover {
  color: unset;
  cursor: default;
}

.fullwidth:hover::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background: rgba(5, 5, 5, 0.41);
  z-index: 20;
  pointer-events: none;
}

.m-cont {
  max-width: initial;
}

.m-padd {
  padding: 15px 8px 8px 8px;
}

.loadingBackdrop {
  display: grid;
  place-items: center;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 999999000000;
}

.loadingBackdrop img {
  width: 150px;
}

.m-cont {
  max-width: -moz-fit-content;
  max-width: initial;
}

/* Sticky Header End */

.menupadd {
  padding: 20px 30px;
}

.terms-cond .breadcrumb_Wrapper.breadcrumbProfile {
  padding: 30px 50px 15px 0px;
}

#swirl-short-videos,
.swirl-short-videos {
  margin: 30px auto;
}

.sub-non {
  display: none;
}

.sub-blk {
  display: block;
  position: absolute;
  background: var(--white);
  color: var(--dark);
  border: 1px solid var(--white);
  width: 100%;
  max-width: 80%;
  height: 480px;
  left: 50%;
  overflow-y: scroll;
  transform: translate(-50%, 0%);
  padding: 20px 30px;
  margin: 23px 0;
  z-index: 9998;
  box-shadow: 1px 2px 5px #aaaaaa;
}

@media screen and (max-width: 992px) {
  .bottomtotop .circle {
    z-index: 99;
    bottom: 60px;
  }
}

/* Breadcrumb Css Starts here */
.breadcrumb_Wrapper {
  font-size: 10px;
  font-weight: 400;
  font-family: var(--font-family), sans-serif;
  width: fit-content;
  position: relative;
  margin: 8px 0 20px 0 !important;
  position: relative;
  top: 10px;
}

@media screen and (max-width: 600px) {
  .breadcrumb_Wrapper {
    padding: 10px 0 0 0;
  }
}

@media screen and (max-width: 480px) {
  .breadcrumb_Wrapper .breadcrumb-item.active {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 380px) {
  .breadcrumb_Wrapper .breadcrumb-item.active {
    font-size: 12px !important;
  }
}

@media screen and (max-width: 360px) {
  .breadcrumb_Wrapper .breadcrumb-item.active {
    font-size: 11px !important;
  }
}

@media screen and (max-width: 768px) {
  .breadcrumb_Wrapper {
    display: none !important;
  }

  .terms-cond {
    padding-top: 40px;
  }
}

.breadcrumb_Wrapper .breadcrumb-item a {
  color: #bebebe;
  text-decoration: none;
}

.breadcrumb_Wrapper .breadcrumb-item-profile a {
  color: #bebebe;
  font-size: 10px;
}

.breadcrumb_Wrapper .breadcrumb-item.active {
  color: var(--dark);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 3px;
}

.breadcrumb_Wrapper .breadcrumb-item-profile.active {
  color: var(--dark);
  text-transform: uppercase;
}

/* Breadcrumb Css Ends here */

@media screen and (max-width: 767px) {
  .pdpThump1 {
    display: none !important;
  }

  .pdpThump2 {
    display: flex !important;
  }

  .minHightPDPMob {
    min-height: 200px !important;
  }
}

@media screen and (min-width: 768px) {
  .pdpThump1 {
    display: block !important;
  }

  .pdpThump2 {
    display: none !important;
  }
}

@media screen and (max-width: 992px) {
  .terms-cond .breadcrumb_Wrapper.breadcrumbProfile {
    padding: 30px 50px 15px 0px !important;
  }

  #b2b-landing {
    margin-top: -38px;
  }
}

.login .text-danger {
  margin: 0;
}

.login .text-danger {
  margin-bottom: 40px;
}

.signin .text-validation {
  margin-bottom: 50px !important;
}

.contact-us .breadcrumb-item + .breadcrumb-item::before {
  content: '';
}

.sitemap .breadcrumb-item + .breadcrumb-item::before {
  content: '';
}

@media screen and (max-width: 600px) {
  .login .divider {
    margin: 0px auto;
    font-size: 14px;
  }

  .login .text-danger {
    margin-bottom: 10px;
  }

  .login input[type='submit']:is(.btntrans) {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 1024px) {
  .nopr {
    margin: 0 350px;
  }
}

@media screen and (max-width: 768px) {
  .nopr {
    margin: 0 250px;
  }

  .breadcrumbProfile:first-child {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .nopr {
    margin: 0 50px;
  }

  .loginpopup_otpinput__DdnH\+ {
    width: 40px !important;
  }
}

@media screen and (max-width: 426px) {
  .tab-view-mr {
    border: 0;
    margin-top: 0px;
  }

  .ordersProfileWrapper {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
  }

  .breadcrumb_Wrapper.breadcrumbProfile {
    padding: 10px !important;
  }
}

@media screen and (max-width: 376px) {
  .nopr {
    margin: 0 25px !important;
  }
}

@media screen and (max-width: 320px) {
  .nopr {
    margin: 0;
  }

  .loginpopup_otpinput__DdnH\+ {
    width: 30px !important;
    height: 40px !important;
  }

  .contact-us .breadcrumb_Wrapper.breadcrumbProfile {
    padding: 20px 15px 0 15px !important;
    font-size: 14px;
  }

  .contact-us .tabs_o_outer {
    margin: 20px 0 !important;
  }

  .exclusives .img-wrapper > * {
    height: auto !important;
  }
}

.product-slick-slider .slick-track {
  display: flex;
  justify-content: center;
}

@media (min--moz-device-pixel-ratio: 1.49) and (max--moz-device-pixel-ratio: 1.51),
  (-o-min-device-pixel-ratio: 149/100) and (-o-max-device-pixel-ratio: 151/100),
  (-webkit-min-device-pixel-ratio: 1.49) and (-webkit-max-device-pixel-ratio: 1.51),
  (min-device-pixel-ratio: 1.49) and (max-device-pixel-ratio: 1.51) {
  .makeup-options {
    zoom: 100% !important;
  }

  .exclusives .img-wrapper {
    margin: 0 4px;
  }

  .homeOffers .slick-slide {
    margin: 0 4px;
  }

  /* Apply styles for 150% zoom */
}

@media (min--moz-device-pixel-ratio: 1.49) and (max--moz-device-pixel-ratio: 1.51),
  (-o-min-device-pixel-ratio: 149/100) and (-o-max-device-pixel-ratio: 151/100),
  (-webkit-min-device-pixel-ratio: 1.49) and (-webkit-max-device-pixel-ratio: 1.51),
  (max-width: 1400px),
  (min-device-pixel-ratio: 1.49) and (max-device-pixel-ratio: 1.51) {
  .product_listing_header {
    zoom: 85%;
  }

  .looks-section {
    zoom: 85.7%;
  }
}

/* POPOVER USED GLOABLLY */

.alertPopovers {
  font-family: var(--font-family), sans-serif;
  text-transform: capitalize;
  font-size: 8px;
  font-weight: 300;
  line-height: 10.5px;
  max-width: 200px;
  z-index: 1019;
  background-color: var(--white);
  color: var(--dark);
  border-color: var(--dark);
}

.loyText {
  text-transform: unset;
}

.alertPopovers .popover-arrow::after {
  border-top-color: var(--white);
}

.alertPopovers .popover-arrow::before {
  border-top-color: var(--dark);
  margin-bottom: 0.5px;
}

.alertPopovers .popover-header {
  background-color: var(--wine);
  font-size: 14px;
  border-color: #787878;
  padding: 8px 10px;
}

.alertPopovers .popover-body {
  padding: 10px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: var(--dark);
}
