.mb-20 {
  margin-bottom: 20px;
}

.freeItems {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.freeItems .accordion-item {
  background-color: #6310110d;
  border-radius: 6px !important;
  padding: 10px 10px;
  border: none;
}

.freeItems .accordion-button {
  background-color: #63101100;
  padding: 0;
}

.freeItem-autoList {
  background-color: #6310110d;
  padding: 10px 10px;
  border-radius: 6px;
}

.freeItem-mnl {
  display: flex;
  gap: 5px;
}

.freeItem-mnl h4 {
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  color: var(--wine);
  margin-bottom: unset;
}

.freeItem-atMnlList {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  row-gap: 8px;
  column-gap: 24px;
  margin-top: 5px;
}

.freeItem-bdr .accordion-button:focus {
  box-shadow: none;
}

.freebie-qty-selected {
  font-weight: 300;
  font-size: 9px;
  line-height: 100%;
  color: #631011;
  padding-left: 3px;
}

.freeItem-bdr .accordion-header:focus-visible,
.freeItem-bdr .accordion-button:focus-visible {
  outline: none;
}

.freeItem-bdr .accordion-button::after {
  display: none;
}

.freeItem-bdr .accordion-button:not(.collapsed) {
  box-shadow: unset;
}

.freeItem-bdr .accordion-body {
  padding: unset;
}

.freeItem-list {
  background-color: var(--white);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 17px;
  padding: 10px;
  position: relative;
  border-radius: 6px;
}

.freeItem-mnlList {
  position: relative;
  display: flex;
  flex: 1;
}

.freeItem-details {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
}

.freeItem-details.freeDisabled {
  opacity: 0.4;
}

.freeItem-list .login-checkbox-button__input:disabled {
  border: 1px solid #d9d9d9;
  cursor: not-allowed;
}

.freeItem-details > img {
  width: 45px;
  aspect-ratio: 1/1;
}

.freeItem-brand {
  font-size: 12px;
  font-weight: 500;
}

.freeItem-details .login-checkbox-button__input {
  width: 14px;
  height: 14px;
}

.freeItem-details .login-checkbox-button__input:checked::before {
  margin-bottom: 0;
}

.freeItem-name {
  font-size: 14px;
  font-weight: 300;
  line-height: 19px;
  color: #686767;
  margin-bottom: 4px;
}

.freeItem-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 11px;
}

.freeItem-price {
  text-decoration-line: line-through;
}

.freeItem-tag {
  color: #23b93c;
}

.freeItem-mnlPick button {
  height: 27px;
  width: 88px;
  font-size: 10px;
  line-height: 22px;
  cursor: pointer;
  border-radius: 6px;
}

.free-item-close {
  height: 10px;
  width: 10px;
  margin-top: -15px;
}

.freeItem-mnl > div > div {
  font-size: 10px;
  font-weight: 300;
  color: #787878;
}

@media (max-width: 991px) {
  .freeItem-atMnlList {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 8px;
  }
}

.freeItem-bdr .cartProduct-quantityInput,
.freeItem-bdr .cartProduct-quantityDropDown {
  border-radius: 4.5px;
  height: 14px;
  width: 34px;
  font-size: 10px;
}

.freeItem-bdr .cartProduct-quantityDropDown {
    justify-content: center!important;
    gap: 2px!important;
}

.freeItem-bdr .cartProduct-quantityValue {
  font-size: 10px;
}

.freeItem-bdr .cartProduct-quantityDropList {
  width: 35px;
}

.less-item-alert {
  padding-right: 5px;
  font-weight: 400;
  font-style: italic;
  font-size: 10px;
  line-height: 15px;
  color: #cc0033;
}

.drop-down-num {
  font-size: 10px;
}
