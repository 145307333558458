.placeholderClass .searchbtn .searchicon {
  top: -36px !important;
}

.placeholderClass .closeBtn button {
  top: -21px !important;
}

.search-input {
  position: relative;
  width: calc(100% - 24px);
  height: 35px;
  padding: 6px;
  clear: both;
  border: none;
  font-family: var(--font-family);
  margin-bottom: 7px;
  font-size: 10px;
  background-color: transparent;
  z-index: 9;
  transition: width 10s ease;
  outline: none;
  font-weight: 400;
}

.search {
  position: absolute;
  top: -15px;
  right: 11px;
  width: 276px;
  height: 32px;
  background-color: var(--white);
  z-index: 1020;
  padding: 0;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
}

.search-for-words {
  margin: 3px 0 0 0;
  font-family: var(--font-family);
  font-size: 10px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 20px;
  display: block;
  height: 100%;
  padding-left: 8px;
  color: #686767;
  animation: spin_words 7s infinite ease-in;
  white-space: nowrap;
}

.search-button {
  position: absolute;
  top: 2px;
  right: 0;
}

.searchicon::before {
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 18px;
  line-height: inherit;
  margin: 0;
  vertical-align: top;
  display: inline-block;
  font-weight: normal;
  overflow: hidden;
  text-align: center;
}

.search-icon-img {
  width: 30px;
  height: 13px;
  background-color: var(--white);
}

.search-suggestions {
  position: absolute;
  isolation: isolate;
  top: 18px;
  left: -284.6px;
  right: 10px;
  background-color: var(--white);
  border-radius: 3px;
  z-index: 9998;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.search-suggestions span {
  color: #686767;
  font-weight: 300;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.search-suggestions .searchUl {
  display: block;
  padding: 0px;
  margin-bottom: 0;
  height: auto;
  max-height: 576px;
  overflow-y: hidden;
  border-radius: 3px;
}

.search-suggestions .searchUl::-webkit-scrollbar {
  width: 2px;
}

.search-suggestions .searchUl::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
}

.search-suggestions .searchUl::-webkit-scrollbar-thumb {
  background-color: slategrey;
  outline: 1px solid slategrey;
}

.search-suggestions .searchUl li.search-item {
  all: unset;
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  padding: 10px 20px !important;
  font-size: 12px;
  font-weight: 300;
  color: var(--dark);
  list-style: none;
  transition: all 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
  padding-left: 0;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 28px;
}

.search-suggestions .searchUl li.search-item strong {
  font-weight: 500;
}

.search-suggestions .searchUl li.search-item:hover {
  background: #d9d9d9;
}

.search-suggestions .searchUl li.highlighted {
  background: #d9d9d9;
}

.search-suggestions .searchUl li.search-item::after {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--dark);
}

.search-suggestions .searchUl li.search-item::before {
  content: '';
  width: 20px;
  height: 20px;
  background: var(--dark);
}

.search-suggestions-wrapper {
  position: relative;
  padding-top: 0;
  font-family: var(--font-family);
}

.empty-suggestions {
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.empty-suggestions svg {
  opacity: 0.1;
}

.empty-suggestions h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: unset;
}

.match-key {
  font-size: 12px;
  font-family: var(--font-family);
  padding: 9px 20px;
  background-color: #f2f2f2;
  line-height: 24px;
  display: block;
}

.match-keyword {
  font-size: 12px;
}

.search input {
  caret-color: #656262;
  position: relative;
  bottom: 2px;
}

.search input:-webkit-autofill,
.search input:-webkit-autofill:hover,
.search input:-webkit-autofill:focus,
.search input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.searchbtn {
  position: relative;
  z-index: 9;
}

.searchbtn .searchicon {
  top: -68px !important;
  left: 20px !important;
  color: #606669 !important;
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 25px;
  display: inline-block;
  background: none;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 40px;
}

/* clears the ‘X’ from Internet Explorer */
input[type='search']::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type='search']::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the ‘X’ from Chrome */
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  display: none;
}

.disable-scroll .search {
  z-index: unset !important;
}

/* MOBILE SEARCH */

.m-search .search-suggestions .searchUl {
  max-height: 62vh;
  overflow-y: scroll;
}

.m-search input {
  caret-color: var(--dark) !important;
  outline: none !important;
  border: none;
  font-family: var(--font-family);
  color: var(--dark);
  z-index: 9;
  background-color: transparent;
}

.m-search-outline {
  border: 1px solid #cccccc;
  border-radius: 5px;
}

.m-search-outline-black {
  border: 1px solid var(--dark);
  border-radius: 5px;
  height: 48px;
}

.m-search {
  font-family: var(--font-family);
  position: fixed;
  width: 100%;
  left: 0;
  box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%);
  background-color: var(--white);
  padding: 10px 16px 10px;
  z-index: 2;
}

.m-search .placeholder-container span {
  top: -36px;
  left: 46px;
}

.m-search .placeholder-container .placeholder-text {
  left: 134px;
}

.m-search-input {
  outline: none;
  width: 100%;
  background-color: transparent;
  z-index: 2;
  border-radius: 5px;
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 400;
  padding: 11px 20px 11px 44px;
  position: relative;
}

.m-searchbtn {
  position: relative;
  z-index: 2;
}

.m-searchbtn .m-searchicon {
  top: -44px !important;
  left: 15px !important;
  color: #606669 !important;
  position: absolute;
  z-index: 1;
  height: 40px;
  width: 25px;
  display: inline-block;
  background-image: none;
  background-color: var(--white);
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 40px;
}

.m-closeBtn button {
  top: -44px;
  right: 10px;
  z-index: 2;
}

.m-search-icon-img {
  width: 24px;
}

.placeholder-container {
  position: relative;
  font-family: var(--font-family);
  color: #686767;
  z-index: 1;
}

.closeBtn {
  position: relative;
  z-index: 9;
}

.closeBtn button {
  top: -51px;
  right: 5px;
  color: #606669;
  position: absolute;
  z-index: 1;
  background: none;
  border: 0;
  box-shadow: none;
  margin: 0;
  padding: 0;
  text-decoration: none;
  text-shadow: none;
  font-weight: 400;
  line-height: 20px;
  background-color: var(--white);
}

.searchBackdrop {
  width: 100%;
  height: 84vh;
  position: absolute;
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 991px) {
  .placeholderClassMobile .m-searchbtn .m-searchicon {
    top: -32px !important;
  }

  .placeholderClassMobile .m-search-outline {
    height: 48px;
  }

  .m-search-input {
    width: 94.5% !important;
  }
}

@media (max-width: 992px) {
  .search-for-words {
    line-height: 1rem;
  }

  .search-suggestions {
    width: 100%;
    height: auto;
    left: 0;
    top: 67px;
    max-width: 100%;
  }

  .empty-suggestions {
    height: 200px;
  }

  .m-search-suggestions {
    width: 100%;
  }

  .m-closeBtn button {
    top: -37px !important;
  }

  .searchBackdrop {
    height: auto;
    left: 0;
    top: 68px;
  }
}

@media (max-width: 1024px) {
  .search {
    width: 300px;
  }

  .search-input {
    width: 300px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .searchicon::before {
    font-size: 14px;
  }
}
