.registerFormWrapper {
  padding-top: 50px;
  padding-bottom: 50px;
}

.registerFormWrapper .login__form .subText {
  margin-bottom: 20px;
}

.registerForm h5 {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 10px;
}

.registerForm .regIntro {
  color: #787878;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}

.registerFormOuter {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.registerFormOuter::-webkit-scrollbar {
  width: 0;
  display: none;
}

.regFormGroup {
  position: relative;
  border-radius: 6px;
  margin-top: 15px;
  display: flex;
  align-items: center;
}

.regFormGroup > label {
  position: absolute;
  font-size: 10px;
  line-height: 14px;
  text-transform: capitalize;
  background-color: var(--white);
  padding: 0 4px;
  left: 10px;
  top: -8px;
  z-index: 1;
}

.regFormGroup > input {
  border: 1px solid #72777a;
  border-radius: 6px;
  width: 100%;
  color: #72777a;
  font-size: 12px;
  line-height: 14px;
  height: 40px;
  padding-left: 10px;
  padding-right: 10px;
}

.regFormGroup > input:focus {
  border-color: var(--dark);
  box-shadow: none;
  outline: none;
  color: var(--dark);
}

.regFormGroup > input:not(:placeholder-shown) {
  border-color: var(--dark);
  color: var(--dark);
}

.regFormGroup > input:disabled,
.regFormGroup > input:-internal-autofill-selected {
  background-color: var(--white) !important;
  color: #72777a;
}

.regFormGroup svg {
  position: absolute;
  right: 10px;
  margin: auto 0;
}

.regFormGroup .downArrow {
  left: 35px;
  right: unset;
}

.regFormGroup .separator {
  position: absolute;
  height: 16px;
  border-right: 1px solid #d9d9d9;
  left: 54px;
}

.registerErrors {
  color: #c03;
  font-size: 10px;
  font-weight: 300;
  line-height: 8px;
  margin-top: 8px;
  margin-bottom: 0;
}

/* SignIn */

.login__form .register_amt {
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 32px;
  text-align: center;
}

.formInputs .loginInput {
  border-radius: 6px;
  border: 1px solid #72777a;
  display: flex;
  height: 40px;
  color: #72777a;
  font-size: 12px;
  line-height: 14px;
  padding-left: 10px;
}

.formInputs .loginInput:focus-within {
  border-color: var(--dark);
  color: var(--dark);
}

.formInputs .loginInput .countryCode {
  display: flex;
  align-items: center;
  border-right: 1px solid #d9d9d9;
  margin: 12px 0;
}

.formInputs .loginInput svg {
  margin: 0 3px;
}

.formInputs .loginInput input {
  border: none;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  border-radius: 6px;
  color: #72777a;
}

.formInputs .loginInput input:not(:placeholder-shown) {
  color: var(--dark);
}

.formInputs .loginInput input:focus {
  color: var(--dark);
}

.formInputs .loginInput input:focus-visible {
  border: none;
  outline: none;
}

.login-checkbox-button {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
}

.signup_fotter_text {
  font-weight: 400 !important;
  font-size: 10px !important;
  line-height: 15px;
  margin-bottom: 0;
  font-style: normal;
  margin-left: 10px;
}

.signup_fotter_text a,
.signup_fotter_text a:hover,
.signup_fotter_text a:focus {
  color: var(--dark);
}

@media (max-width: 768px) {
  .login__form .register_amt {
    margin-bottom: 20px;
  }
}
