.cartDropdown {
  position: absolute;
  z-index: 9999;
  top: 47px;
  transform: translateX(-30%);
  background-color: var(--white);
  width: 400px;
  right: -133px;
  padding: 20px 20px 20px 14px;
  box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.cartDropdown h3 {
  font-size: 14px;
  text-transform: uppercase;
  border-bottom: 1px solid #787878;
  margin-bottom: 10px;
  padding-bottom: 10px;
  margin-left: 6px;
}

.cartDropdown .cartDropdownProductWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 360px;
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-left: 6px;
}

.cartDropdown .cartDropdownProductWrapper::-webkit-scrollbar {
  width: 0.1em;
}

.cartDropdown .cartDropdownProductWrapper::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.cartDropdown .upperWrapper {
  display: flex;
  gap: 10px;
}

.cartDropdown .dropdownFooter span {
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
}

.cartDropdown .dropdownFooter {
  text-align: center;
  margin-top: 20px;
  margin-left: 6px;
}

.cartDropdown .dropdownFooter > div {
  border-bottom: 1px solid #787878;
  display: flex;
  justify-content: space-between;
  padding-bottom: 6px;
  margin-bottom: 10px;
}

.cartDropdown .dropdownFooter button {
  width: 260px;
  height: 40px;
  border-radius: 6px;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 400;
  padding-top: 8px;
  padding-bottom: 8px;
}

.cart-pop-over {
  position: relative;
  box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  box-shadow: 3px -2px 4px #aaa;
  border: 0;
}

.cart-pop-over-arrow {
  position: absolute;
  left: 334px;
  top: -24px;
  border: 2px solid #77353b;
  border-radius: 3px;
  width: 32px;
  height: 0;
  filter: none;
}

.cart-pop-over-arrow-select-aria {
  position: absolute;
  top: -42px;
  left: 285px;
  height: 22px;
  width: 85px;
}