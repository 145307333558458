/* POP OVER */

.hed {
  border: 0 !important;
  border-radius: 4px !important;
  font-family: var(--font-family);
}

.hed .popover-body {
  padding: 0px;
  width: 95%;
}

.hed .pop-signout-li {
  margin: 12px !important;
}

.hed .pop-head {
  padding: 0px;
  background-color: var(--white);
  box-shadow: 1px 3px 6px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  width: fit-content !important;
  align-items: center;
  justify-content: center;
  height: fit-content !important;
}

.hed .pop-signout-li {
  list-style: none;
  width: fit-content;
  height: fit-content;
}

.hed .list-group-item {
  margin: 0;
}

.hed li:has(.list-group-item-action):active {
  background: var(--dark);
  color: var(--white);
}

.hed-sign-in-popup {
  left: -43 !important;
}

.hed {
  left: -50px !important;
  display: flex;
  position: absolute;
  z-index: 9999;
  left: 28%;
  top: 46px;
  transform: translateX(-30%);
  padding: 0 !important;
  background-color: transparent;
}

.hed-sign-in-popup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 65px;
}

.pop-over {
  display: flex;
  justify-content: center;
  align-items: center !important;
  position: relative;
  background-color: rgb(233, 212, 222);
  border-radius: 4px;
  top: 50px;
  box-shadow: 3px -2px 4px #aaa;
  border: 0;
}

.pop-over-arrow {
  position: absolute;
  top: -68px;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 18px solid var(--white);
  width: 0;
  height: 0;
  filter: drop-shadow(0 -2px 2px #aaa);
}

.pop-over-arrow-cursor-position {
  position: absolute;
  top: -76.5px;
  width: 60px;
  height: 50px;
}

.pop-head {
  text-align: justify;
  width: 180px;
}

.signout a {
  text-decoration: none;
}

.signout-li {
  border: none !important;
  margin: 7px 0;
}

.signout-li:first-child,
.signout-li:last-child {
  margin: 0;
}

.signout-li:last-child {
  border-radius: 0px 0px 4px 4px;
}

.signout-img {
  width: 13px;
  height: 12px;
}

.signout {
  list-style: none;
  width: 181px;
}

.signout-cont {
  font-size: 15px;
  padding-left: 2px;
  font-weight: 400;
}

.signout-li svg {
  margin-right: 2px;
}

.nvbr .txtbld {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 16px;
  padding: 11px 26px 3px 16px !important;
}

/* SUB MENU */

.header-con {
  max-width: 1440px;
}

.menuwidth li.parent:hover span {
  border-bottom: 3px solid var(--wine);
  padding-bottom: 8px;
}

.menuwidth li.parent.no-hover:hover span {
  cursor: unset;
}

.header-center {
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
}

.header-center::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

.header-center::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  background-color: #f5f5f5;
}

.header-center::-webkit-scrollbar-thumb {
  background-color: var(--dark);
  border: 2px solid #555555;
}

.level-top a,
.level-top a:hover,
.level-top a:focus {
  color: var(--dark);
}

.click-submenu {
  position: fixed;
  top: 94px;
  z-index: 99;
  width: 100%;
  background: var(--white);
}

.fullwidthtop .submenu ul li {
  padding: 8px 0;
  font-weight: 400;
}

.fullwidthtop .smul li {
  display: block;
  color: #7a7a7a;
  font-family: var(--font-family), sans-serif;
  font-size: 12px;
  padding: 8px 0;
  margin: 0;
  line-height: 18px;
  letter-spacing: 0.33px;
  border-bottom: 0;
}

.fullwidthtop:hover::before {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100vw;
  z-index: 20;
  pointer-events: none;
}

.fullwidthtop:hover > .submenu {
  background: var(--white);
  border: 1px solid var(--white);
  box-shadow: 0px 7px 11px 0px rgba(0, 0, 0, 0.25);
  color: var(--dark);
  display: flex;
  flex-direction: column;
  gap: 30px;
  left: 50%;
  max-height: 419px;
  max-width: 1300px;
  margin: 32px 0px;
  overflow: hidden;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  z-index: 9999;
  scrollbar-width: thin;
  scrollbar-color: rgb(151, 149, 149) var(--white);
}

.fullwidthtop:hover > .brandsubmenu {
  top: 100px;
}

.submenu::-webkit-scrollbar {
  display: none;
}

.submenu {
  display: none;
  position: absolute;
  background: var(--white);
  color: var(--dark);
  border: 1px solid var(--white);
  width: 100%;
  max-width: 80%;
  left: 50%;
  transform: translate(-50%, 0%);
  margin: 23px 0;
  z-index: 9998;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

.submenu:hover {
  color: #bd0100;
}

li.parent:hover {
  color: var(--wine);
}

.fullwidth:hover > .submenu {
  display: block;
}

.submenu ul {
  padding: 0;
}

.no-hover .submenu {
  display: none !important;
}

.submenu ul li {
  font-family: var(--font-family), sans-serif !important;
  font-size: 12px !important;
  padding: 9px 0 !important;
  margin: 0 !important;
  font-weight: 300 !important;
  border-bottom: 0 !important;
  color: #7a7a7a !important;
  text-transform: capitalize !important;
}

.submenu ul li:hover {
  color: var(--wine) !important;
}

.submenu ul li a {
  padding: 0;
  color: #7a7a7a;
  font-size: 14px;
  margin-bottom: 10px;
}

.fullwidth:hover > .submenu {
  display: block;
  position: absolute;
  background: var(--white);
  box-shadow: 0px 7px 11px 0px #00000040;
  color: var(--dark);
  border: 1px solid var(--white);
  max-width: 1440px;
  margin: 14px auto;
  max-height: 419px;
  overflow: hidden;
  overflow-y: auto;
  transform: translate(-50%, 0%);
  z-index: 9999;
  scrollbar-width: thin;
  scrollbar-color: rgb(151, 149, 149) var(--white);
}

.submenu ul li:hover {
  border-bottom: 0 !important;
}

vel-top:hover .submenu .d-arr {
  margin-left: 5px;
  vertical-align: text-top;
}

.ctnir {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  align-content: baseline;
  background-color: var(--white);
  border-radius: 3px;
  width: 100%;
  padding: 20px 30px;
}

.griditem {
  display: flex;
  flex-direction: column;
}

.menu-head {
  font-size: 16px;
  height: auto;
  margin-bottom: calc(var(--height)-30px);
  font-family: var(--font-family), sans-serif;
  font-weight: 500;
  text-transform: capitalize;
  cursor: pointer;
}

.menu-head ul li {
  font-size: 11px;
}

.menu-head ul {
  padding: 0 !important;
}

.subcat {
  cursor: pointer;
  font-family: var(--font-family), sans-serif;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.subcat:hover {
  color: var(--wine);
}

.sticky-header ul li .sticky-overlay:hover {
  opacity: 1;
  visibility: visible;
}

/* HEADER CENTER */

.hoverCat:hover span {
  border-bottom: 3px solid var(--wine);
  padding-bottom: 19.5px;
}

.hoverCat.no-hover:hover span {
  border-bottom: 0px;
  cursor: default;
}

.smul li a {
  text-decoration: none;
}

.b2b-landing-overlay {
  position: fixed !important;
}

.nav-trasparent {
  background: transparent !important;
  color: #111;
}

.nav-trasparent ul {
  background: transparent !important;
}

#navbar-top-desktop {
  display: block !important;
  position: fixed !important;
  width: 100% !important;
  top: 0 !important;
  z-index: 1020 !important;
}

/* EXCLUSIVE */

.exclusive {
  background-color: var(--wine);
  color: var(--white);
  font-size: 13px;
  min-height: 30px;
  font-family: var(--font-family);
  font-weight: 400;
  max-height: 40px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  text-transform: uppercase;
  line-height: 11px;
}

.exclusive a {
  color: var(--white);
}

.exclusive span {
  color: var(--white) !important;
  min-width: 1%;
  min-height: 1px;
  font-size: 10px !important;
}

.exclusiveInner {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 30px;
}

.exclusiveInner > * {
  position: absolute;
  white-space: nowrap;
  animation: slide-infinite 10s linear infinite;
  margin-top: 10px;
}

.getAppCta {
  display: flex;
  white-space: nowrap;
  align-items: center;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 14px;
  gap: 2px;
}

.getAppCta.underline {
  text-decoration: underline;
  text-transform: lowercase;
}

/* MAIN HEADER */

.nav-head {
  background-color: var(--white);
  height: 64px;
}

.header-row > div {
  display: flex;
  align-items: center;
  width: 0%;
}

.header-left ul li {
  font-weight: 600;
  font-family: var(--font-family);
  text-transform: uppercase;
  color: var(--dark);
  font-size: 14px;
}

.header-left {
  flex: 1;
  justify-content: center;
}

.header-left ul li:last-child::after {
  display: none;
  content: '';
}

.header-left ul a {
  text-decoration: none;
  color: var(--dark);
}

.brand {
  padding: 5px 0;
}

.brand .brandLogo {
  display: block;
  object-fit: contain;
  width: 229px;
  height: 25px;
}

.brand .b2b_brandLogo {
  height: 41px;
  display: block;
  object-fit: contain;
  width: 229px;
}

/* HEADER RIGHT */

.header-right {
  justify-content: flex-end;
}

.header-right .h-icon {
  padding: 0 12px;
}

.header-right .h-icon > * {
  cursor: pointer;
}

.user-icon {
  position: relative;
}

.h-icon {
  position: relative;
}

.dropdown-toggle::after {
  display: none !important;
}

.usname,
.usrname {
  color: var(--wine);
  font-family: var(--font-family);
  font-size: 8px;
  line-height: 7px;
  overflow: hidden;
  padding: 5px 0;
  position: absolute;
  text-align: center;
  margin-left: -10px;
  width: 100%;
  text-overflow: ellipsis;
}

.usname {
  left: -15px;
  top: 28px;
  width: 49px;
  z-index: 99;
}

.shop-icon-chip-h {
  position: absolute;
  display: grid;
  font-family: var(--font-family);
  font-size: 7px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  place-content: center;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  padding: 8px;
  background: var(--wine);
  color: var(--white);
  top: 0%;
  left: 5px;
}

.b2BLoginRegister {
  font-family: var(--font-family);
  padding: 8px 20px;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
}

.disable-scroll .sub-menu-wrapper-b2b {
  display: none !important;
}

/* STICKY HEADER */

.sticky-header {
  border-bottom: 1px solid #e7e7e7;
}

.sticky-header ul {
  list-style-type: none;
  background-color: var(--white);
  margin-top: 0;
  justify-content: center;
}

.sticky-header ul li {
  padding: 14px 20px 8px 18px;
  color: var(--dark);
  font-weight: 500;
  font-size: 1em;
  font-family: var(--font-family), sans-serif;
  line-height: 11px;
  position: static;
  border-bottom: 6px solid var(--white);
  cursor: pointer;
  font-size: 13px;
  text-transform: uppercase;
}

/* Mobile Header */

.m-nvbr .list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  word-wrap: break-word;
  width: 142px;
  white-space: break-spaces;
}

.m-nvbr .txtbld {
  font-family: var(--font-family);
  font-size: 18px;
  font-weight: 600;
}

.m-nvbr .dropdown-menu[data-bs-popper] {
  top: 40px;
  left: -50px;
  width: -moz-fit-content;
  width: fit-content;
  padding: 20px;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.m-header.sticky-top {
  border-bottom: 0;
}

.sticky-top {
  border-bottom: 1px solid #d9d9d9;
}

.m-navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu-logo-sec {
  display: flex;
  padding: 14px 22px;
  margin-bottom: 10px;
  position: absolute;
  right: 0;
  cursor: pointer;
  z-index: 99;
}

.menu-logo-sec .m-logo img {
  width: 80%;
  margin-left: 15%;
}

.btn-nav {
  color: #000;
  text-decoration: none;
}

.m-side-close {
  cursor: pointer;
  width: 23px;
}

.sidebar-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1;
}

.sidebar-overlay.active {
  opacity: 1;
  visibility: visible;
}

.navbar-nav li {
  margin: 0 !important;
}

@media screen and (min-width: 320px) and (max-width: 990px) {
  .hed .list-group-item {
    border: 0;
  }

  .hed {
    border: 0 !important;
    border-radius: 4px !important;
    font-family: var(--font-family);
  }

  .hed .popover-body {
    padding: 0px;
    width: 95%;
  }

  .hed .pop-signout-li {
    margin: 12px !important;
  }

  .hed .pop-head {
    padding: 0px;
    background-color: var(--white);
    box-shadow: 1px 3px 6px 1px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    width: fit-content;
  }

  .hed .pop-signout-li {
    padding: 12px 14px;
  }

  .hed {
    position: absolute;
    z-index: 9999;
    left: 0 !important;
    top: 54px;
    transform: translateX(-30%);
    width: 250px;
    padding: 0 !important;
    background-color: transparent;
  }

  .pop-over {
    position: relative;
    background-color: rgb(233, 212, 222);
    border-radius: 4px;
    top: 50px;
    left: 50px;
    box-shadow: 3px -2px 4px #aaa;
    border: 0;
  }

  .pop-over-arrow {
    position: absolute;
    top: -71.5px;
    left: 21px;
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 20px solid var(--white);
    width: 0;
    height: 0;
    filter: drop-shadow(0 -2px 2px #aaa);
  }

  .pop-head {
    text-align: justify;
    width: 180px;
  }

  .pop-head .dropdown-item:hover {
    color: var(--white);
  }

  .signout-cont {
    font-size: 14px;
  }
  .signout {
    width: 142px;
  }

  .pop-user-name {
    margin-left: 3px;
    top: 24px;
  }
}

@media screen and (max-width: 768px) {
  .skeleton-header-mob {
    display: none;
  }

  .signout-cont {
    font-size: 14px;
  }

  .signout-li svg {
    margin-right: 8px;
  }
}

@media (min-width: 992px) and (max-width: 1300px) {
  .submenu ul li {
    font-size: 12px;
  }

  .fullwidth:hover > .submenu {
    max-width: 92%;
  }

  .sticky-header ul li {
    font-size: 14px;
  }

  .menu-head {
    font-size: 14px;
  }
}

@media (min-width: 1200px) and (max-width: 1470px) {
  .fullwidth:hover > .submenu {
    max-width: 95%;
  }

  .menu-head {
    font-size: 14px;
  }
}

@media (min-width: 1020px) and (max-width: 1200px) {
  .fullwidth:hover > .submenu {
    max-width: 96%;
  }
}

@media (min-width: 992px) and (max-width: 1020px) {
  .fullwidth:hover > .submenu {
    max-width: 95%;
  }
}

@media screen and (max-width: 1280px) {
  .header-content,
  .exclusive {
    padding: 0 20px;
  }
}

@media (max-width: 991px) {
  .exclusive {
    padding: 0 20px;
  }

  .exclusiveInner > * {
    animation-duration: 4s;
  }

  .getAppCta {
    font-size: 11px;
    top: 10px;
    right: 24px;
  }

  .b2BLoginRegister {
    padding: 4px 10px;
    font-size: 10px;
    font-weight: 400;
  }

  .m-header {
    display: block;
    margin-bottom: 77px;
  }

  .m-header-margin {
    margin-bottom: 0px;
  }

  .cart-m-header {
    margin-bottom: 60px;
  }

  .m-header-main {
    display: block;
    -webkit-transform: translate3d(0, 0, 0);
    width: 100%;
    top: 0;
    box-shadow: 0 1px 0 0 rgb(0 0 0 / 6%);
    background-color: var(--white);
    z-index: 9;
  }

  .m-header-content {
    max-width: 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  .m-header-row > div {
    display: flex;
    align-items: center;
    height: 40px;
  }

  .m-header-left {
    flex: 0 1;
    padding-top: 0;
    padding-bottom: 0;
    min-width: auto;
    margin-right: auto;
    justify-content: flex-start;
  }

  .m-header-center {
    margin: 0 auto;
    justify-content: center;
    padding: 5px 0;
    flex: 1 1;
  }

  .m-header-right {
    flex: 0 1;
    padding-top: 0;
    padding-bottom: 0;
    min-width: auto;
    margin-left: 0;
    justify-content: flex-end;
  }

  .m-header-right .navbar-nav {
    flex-direction: row;
    width: 72px;
  }

  .m-headicon {
    width: 18px;
    height: 17px;
  }

  .m-brand img {
    display: block;
    height: 30px;
    width: 100%;
    object-fit: revert;
  }

  .m-h-icon {
    padding: 0 4px;
    position: relative;
  }

  .m-shop-icon-chip-h {
    position: absolute;
    display: grid;
    font-family: var(--font-family);
    font-size: 10px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    place-content: center;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    padding: 5px;
    background: var(--wine);
    color: var(--white);
    top: -5%;
    left: 0px;
  }
}

@media screen and (max-width: 450px) {
  .m-brand img {
    height: 23px !important;
    width: 150px !important;
  }

  .m-exclusive {
    font-size: 12px;
  }
}

@media screen and (max-width: 376px) {
  .getAppCta {
    right: 10px;
  }

  .m-nvbr {
    padding: 0px 5px 0 8px !important;
  }

  .m-brand img {
    width: 130px !important;
  }

  .m-exclusive {
    font-size: 10px;
  }
}

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .header-left ul li {
    font-size: 14px;
  }
}

@media screen and (min-width: 990px) and (max-width: 1250px) {
  .sticky-header ul li {
    margin: 0;
    height: 40px;
  }
}

@media screen and (max-width: 1024px) {
  .sticky-header ul li {
    margin: 0 -5px;
  }
}

@media screen and (min-width: 320px) and (max-width: 990px) {
  .m-nvbr .list-group-item {
    width: 145px;
  }

  .m-nvbr .txtbld {
    font-size: 16px;
  }

  .m-nvbr {
    padding: 0 0 0 15px !important;
  }

  .m-nvbr .txtbld {
    font-size: 15px;
  }
}

@media screen and (max-width: 426px) {
  .m-exclusive {
    font-size: 10px;
  }
}

@media screen and (min-width: 411px) {
  .m-brand img {
    height: 35px;
  }
}

@media screen and (min-width: 320px) {
  .m-brand img {
    height: 25px;
    width: 130px;
  }
}
