.wishdrop-color-parent {
  height: 100%;
  display: flex;
  align-items: end;
}

.wishdrop-color {
  display: flex;
  gap: 2px;
  margin-bottom: unset;
  align-items: center;
}

.wishdrop-colors {
  display: flex;
  gap: 2px;
  position: relative;
}

.wishdrop-colors .plp-infoHolder-ConfigOptions-color {
  margin-left: -6px;
}

.moreColors-wishlist {
  color: var(--dark);
  font-size: 9px;
  padding-top: 2px;
  font-weight: 300;
  font-family: var(--font-family);
}

.size-products-available {
  margin: 0;
  font-weight: 400;
  font-size: 10px;
  font-family: var(--font-family);
}

.wishdrop-variant-selected {
  font-size: 10px !important;
  font-weight: 400;
  margin-bottom: 0 !important;
  display: inline-block;
}

.variant-selected {
  font-size: 10px !important;
  font-weight: 400;
}

.moreSizes-wishlist {
  font-family: var(--font-family);
  color: var(--dark);
  font-size: 8px;
  padding-top: 2px;
  font-weight: 500;
}

.color-products-available {
  margin: 0;
  font-weight: 400;
}

.plp-infoHolder-ConfigOptions-colorList {
  display: flex;
  grid-template-columns: repeat(auto-fill, 32px);
  column-gap: 5px;
  padding: 0;
}

.dropdownWishlistProd .singshade {
  font-size: 9px;
  font-weight: 300;
}

.dropdownWishlistProd {
  width: auto !important;
}

.dropdownWishlistProd {
  position: relative;
  background: #f5f5f5;
  display: flex;
  padding: 10px;
  flex-direction: column;
  line-height: 12px;
  gap: 10px;
  border-radius: 3px;
}

.dropdownWishlistProd .bottomWrapper {
  text-align: end;
  text-transform: capitalize;
  width: 100%;
  position: absolute;
  right: 10px;
}

.dropdownWishlistProd .bottomWrapper button {
  background-color: transparent;
  border: none;
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 9px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
  line-height: 14px;
}

.dropdownWishlistProd .bottomWrapper button:hover {
  text-decoration: underline;
}

.dropdownWishlistProd .rPrice {
  color: #787878;
  font-size: 10px;
  font-family: var(--font-family);
  font-weight: 400;
  position: relative;
  line-height: 16px;
}

.dropdownWishlistProd .dis {
  color: #77353b;
  font-size: 12px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
  padding-top: 1px;
}

.dropdownWishlistProd .imgWrapper img {
  margin-top: 0%;
  height: 70px !important;
  border-radius: 6px;
  width: 70px !important;
}

.mini-wishdrop-prod {
  display: flex;
  gap: 10px;
}

.wishdrop-details {
  display: flex;
  flex-direction: column;
  gap: 3.3px;
}

.wishdrop-brand {
  color: var(--dark);
  font-size: 12px;
  font-family: var(--font-family);
  margin-bottom: unset;
  font-weight: 500;
  line-height: 10px;
}

.wishdrop-brandname {
  color: #686767;
  font-weight: 300;
  font-family: Poppins;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  height: 17px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 255px;
}

.wishdrop-price {
  display: flex;
  gap: 11px;
  min-height: 20px;
  font-family: var(--font-family);
}

.wishdrop-price-child {
  display: flex;
  align-items: center;
  line-height: 4px;
}

.wishdrop-fp {
  font-weight: 600;
  font-size: 12px;
  padding-top: 1.2px;
}

.strike-dis {
  color: #b6b6b6;
  position: relative;
  text-decoration: line-through;
}

.wishdrop-rp {
  font-weight: 400;
  font-size: 12px;
  padding-top: 1.2px;
}

.wishdrop-dis {
  display: flex;
  align-items: center;
  line-height: 6px;
}

.wishdrop-close-icon {
  position: absolute;
  top: 8px;
  right: 10px;
}
