.dropdownCartProd {
  position: relative;
  background: #f5f5f5;
  display: flex;
  padding: 10px;
  flex-direction: column;
  line-height: 17px;
  border-radius: 3px;
}

.dropdownCartProd.free {
  background: #6310110A;
}

.dropdownCartProd .wish-pop {
  line-height: 0.7rem;
  margin-top: 0;
}

.dropdownCartProd .imgWrapper-info {
  gap: 4.6px;
}

.dropdownCartProd h4 {
  color: var(--dark);
  font-size: 12px;
  font-family: var(--font-family);
  margin-bottom: unset;
  font-weight: 500;
}

.dropdownCartProd p {
  min-height: 12px;
}

.dropdownCartProd .fPrice {
  color: var(--dark);
  font-family: var(--font-family);
  font-size: 12px;
  font-weight: 600;
}

.dropdownCartProd svg {
  width: auto !important;
}

.dropdownCartProd .rPrice {
  color: #b6b6b6;
  font-size: 12px;
  font-family: var(--font-family);
  font-weight: 400;
  position: relative;
  text-decoration: line-through;
}

.dropdownCartProd .dis {
  color: #77353b;
  font-size: 12px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 300;
}

.dropdownCartProd .imgWrapper {
  margin-top: 0%;
  background-color: var(--white);
  height: 100%;
  display: flex;
  align-items: center;
  border-radius: 6px;
}

.dropdownCartProd .imgWrapper img {
  margin-top: 0%;
  height: 70px !important;
  border-radius: 6px;
  width: 70px !important;
}

.dropdownCartProd .configOptions {
  font-size: 9px;
  text-transform: capitalize;
  display: flex;
  font-weight: 400;
  font-family: var(--font-family);
  align-items: center;
}

.dropdownCartProd .configOptions .color {
  width: 8px;
  height: 8px;
  border-radius: 10px;
}

.dropdownCartProd .bottomWrapper {
  text-align: end;
  text-transform: capitalize;
  font-size: 9px;
  font-weight: 300;
  font-family: var(--font-family);
}

.dropdownCartProd .bottomWrapper button {
  background-color: transparent;
  border: none;
  color: var(--dark);
}

.dropdownCartProd .bottomWrapper button:hover {
  text-decoration: underline;
}

.cartdrop-productname {
  color: #686767;
  font-weight: 300;
  font-family: Poppins;
  font-size: 11px;
  line-height: 14px;
  margin-bottom: 0 !important;
  text-overflow: ellipsis;
  height: 17px;
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  max-width: 273px;
}

.wish-pop {
  line-height: 0.5rem;
  margin-top: 5px;
}

.cart-name {
  position: relative;
  left: 2px;
}

.dropdownFreeItem {
  font-size: 12px;
}