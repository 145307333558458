.wishlistDropDown .moreColors-wishlist {
    position: relative;
    left: 10px;
    padding-top: 2px;
  }
  
  .wishlistDropDown .cart-pop-over-arrow {
    position: absolute;
    top: -24px;
    border: 2px solid #77353b;
    border-radius: 3px;
    width: 32px;
    height: 0;
    filter: none;
  }
  
  .wishlistDropDown {
    right: -178px !important;
  }
  
  .wishlistDropDown .dropdownCartProd .wishdrp-p {
    min-height: 34px;
  }
  
  .wishlistDropDown .plp-infoHolder-ConfigOptions-colorList {
    line-height: 15px;
  }
  
  .wishlistDropDown .plp-infoHolder-ConfigOptions-colorList-conf {
    line-height: 1rem;
  }
  