.login {
  width: 100%;
  height: auto;
  font-family: var(--font-family);
  padding-top: 100px;
}

.login__form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.login__form img {
  width: 100%;
  max-width: 273.996px;
  height: auto;
}

.login__form .subText {
  color: #000000;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-top: 30px;
  font-family: Poppins;
}

.login__form .formInputs {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.formInputs label {
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
  margin-bottom: 20px;
}

.login__form .loginCta {
  border-radius: 6px;
  font-size: 10px;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
  margin-top: 50px;
  width: 100%;
  max-width: 260px;
  height: 30px;
}

/* LOGIN WITH OTP */

.login__form .mobConfirmation {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 23px;
}

.login__form .mobConfirmation h6 {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: unset;
}

.login__form .formInnerWrapper {
  width: auto;
}

.resend {
  cursor: pointer;
  border: none;
  background: transparent;
  font-weight: 500;
  float: right;
  font-size: 14px;
  align-self: flex-end;
  color: var(--dark);
  margin-top: 8px;
}

.resend u {
  text-decoration: underline;
  color: grey;
}

.input__holder input::placeholder {
  color: #72777a;
}

.input__holder input::-moz-placeholder {
  color: #72777a;
}

.input__holder input::-webkit-input-placeholder {
  color: #72777a;
}

.input__holder {
  margin: 0;
  justify-content: center;
}

/* checkbox Css */

.login-checkbox-button__input {
  cursor: pointer;
  width: 16px;
  height: 16px;
  appearance: none;
  position: relative;
  border: 1px solid var(--dark);
  border-radius: 3px;
}

.login-checkbox-button__input::before {
  content: '';
  display: grid;
  place-content: center;
  position: absolute;
}

.login-checkbox-button__input:checked {
  background-color: var(--wine);
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--wine);
}

.login-checkbox-button__input:checked::before {
  background: url('/src/assets/icons/Check.svg') no-repeat center center;
  background-size: contain;
  width: 8px;
  aspect-ratio: 1/1;
  margin-bottom: 1px;
}

/* Checkbox Css Ends Here */

.resend:disabled {
  cursor: not-allowed;
}

.resend-code {
  display: inline-block;
  min-width: 42px !important;
}

@media screen and (max-width: 600px) {
  .login {
    padding: unset;
    height: 100%;
  }

  .login__form img {
    width: 180px;
  }

  .login__form .subText {
    max-width: 354px;
  }

  .login__form {
    height: 100%;
  }

  .login__form .subText br {
    display: block;
  }

  .login__form .formInputs {
    justify-content: space-between;
    height: 100%;
  }

  .login__form .formInnerWrapper {
    width: unset;
  }

  .login__form .loginCta {
    max-width: unset;
    margin-top: 7px;
  }
}
