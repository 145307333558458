/* SideBar */
.sidebar {
  width: 100%;
  min-height: 100vh;
  box-shadow: 0px 4px 8px rgb(0 0 0 / 16%);
  background-color: #f4f4f4;
  position: fixed;
  top: 0;
  left: -100%;
  overflow-x: hidden;
  z-index: 99;
  transition: 0.5s;
}

.sidebar.active {
  left: 0;
}

.multilevelSideNav {
  padding-bottom: 100px;
}

.sidebar.active a {
  text-decoration: none;
  color: #000;
}

/* ============================================ Mobile SideNav Bar Menu ============================= */
.sidelist {
  justify-content: space-evenly;
}

.sidemenu .nav {
  margin-bottom: 0px;
}

.sidemenu .nav-link {
  padding: 26px 28px;
  background: #afafaf !important;
  color: #fff !important;
  border-radius: 0;
  font-size: 14px;
  width: auto;
  font-family: 'Avalon';
  border-right: 4px solid #afafaf;
  border-radius: 0 !important;
}

.sidemenu .nav-tabs:active {
  border-bottom: 0 !important;
}

.sidemenu .nav-tabs .nav-link.active {
  border: 0 !important;
  background: #f6f6f6 !important;
  color: #495057 !important;
}

.sidemenu .nav-tabs {
  border: 0;
}

.sidemenu .tab-content {
  padding: 20px 5px;
}

.s-menu-head > a:before {
  content: '\f801';
  display: inline;
  font-family: 'porto-icons';
  margin-right: 10px;
  font-size: 16px;
  vertical-align: top;
  color: #aaaaaa;
}

/* ========================== SignInRegister =================================*/
.signinregister {
  font-family: Avalon;
}

.sign-bg-img {
  background-color: #f8f4f4;
  height: 100vh;
}

.signinregister img,
.register img,
.confirmsignup img,
.signin img,
.resetpassword img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
}

.sign-icon img {
  height: 30px;
  width: 30px;
  margin: 0 20px;
  align-items: center;
}

.line {
  width: 100%;
  text-align: center;
  border-bottom: 0.25px solid #a5a5a5;
  line-height: 0.1em;
  margin: 10px 0;
}

.line span {
  background: #fff;
  padding: 0 10px;
}

.err {
  padding-top: 10px;
  color: red;
}
