@keyframes slide-infinite {
  0% {
    transform: translateX(800%);
  }
  100% {
    transform: translateX(-100%);
  }
}

.m-exclusive {
  background-color: #efefef;
  color: var(--dark);
  font-size: 14px;
  font-family: var(--font-family);
  font-weight: 400;
}

.m-exclusive a {
  color: var(--dark);
}

.header-content {
  position: relative;
  padding: 0;
  height: 100%;
}

.cat-clr {
  color: transparent;
}

.cat-clr-b {
  color: var(--dark) !important;
}

.highlited {
  background: #d9d9d9;
}

.wrapper {
  font-weight: 300 !important;
  font-size: 8px;
  line-height: 12px;
  margin-left: 10px;
  box-sizing: content-box;
  background-color: var(--white);
  height: 20px;
  display: flex;
  margin-top: -39px;
  z-index: 2;
  contain: content;
}

.wrapper p {
  color: #686767;
  font-weight: 400;
  text-transform: capitalize;
  font-family: var(--font-family);
  line-height: 24px;
  margin: 0;
  margin-right: -5px;
  font-size: 10px;
  margin-top: 0.7px;
  white-space: nowrap;
}

@keyframes spin_words {
  0% {
    transform: translateY(100%);
    opacity: 0.5;
  }

  1%,
  2%,
  3%,
  4%,
  5%,
  6%,
  7%,
  8%,
  9%,
  10% {
    opacity: 0.5;
  }

  11%,
  12%,
  13%,
  14%,
  15%,
  16%,
  17%,
  18%,
  19%,
  20%,
  21%,
  22%,
  23%,
  24%,
  25%,
  26% {
    opacity: 1;
    transform: translateY(-8%);
  }

  27%,
  28%,
  29% {
    opacity: 0.5;
  }

  30% {
    opacity: 0.4;
  }

  31% {
    opacity: 0.3;
  }

  32% {
    opacity: 0.2;
  }

  33% {
    opacity: 0.1;
  }

  34%,
  35% {
    opacity: 0;
  }

  36% {
    opacity: 0.4;
  }

  37%,
  38%,
  39% {
    opacity: 0.5;
  }

  40%,
  41%,
  42%,
  43%,
  44%,
  45%,
  46%,
  47%,
  48%,
  49%,
  50%,
  51%,
  52%,
  53%,
  54%,
  55%,
  56% {
    opacity: 1;
    transform: translateY(-210%);
  }

  57%,
  58%,
  59%,
  60%,
  61%,
  62% {
    opacity: 0.5;
  }

  63% {
    opacity: 0.4;
  }

  64% {
    opacity: 0.3;
  }

  65% {
    opacity: 0.2;
  }

  66% {
    opacity: 0.1;
  }

  67%,
  68%,
  69% {
    opacity: 0;
  }

  70% {
    opacity: 0.4;
  }

  71%,
  72%,
  73% {
    opacity: 0.5;
  }

  74% {
    opacity: 1;
  }

  75%,
  76%,
  77%,
  78%,
  79%,
  80%,
  81%,
  82%,
  83%,
  84%,
  85%,
  86%,
  87%,
  88% {
    opacity: 1;
    transform: translateY(-410%);
  }

  89% {
    opacity: 0.5;
  }

  90%,
  91%,
  92%,
  93% {
    opacity: 0.5;
  }

  94%,
  95% {
    opacity: 0.3;
  }

  96% {
    opacity: 0.2;
  }

  97% {
    opacity: 0.1;
  }

  98%,
  99% {
    opacity: 0;
  }

  100% {
    transform: translateY(-500%);
    opacity: 0;
  }
}

.headicon {
  width: 32px;
  height: 32px;
}

/* Popover */
.nvbrdrp {
  padding: 0 !important;
  z-index: 1030 !important;
  right: -70px;
  position: absolute;
}

.nvbrdrp .dropdown-item {
  width: 181px;
  padding: 15px;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  text-transform: none;
  background: var(--dark);
  color: var(--white);
  border: 10px solid #ededed;
}

.myacc {
  font-size: 18px !important;
  font-weight: 700;
  background-color: #d9d9d9 !important;
  box-shadow: 0px 4px 5px 0.1px rgba(0, 0, 0, 0.3);
}

.myacc:hover {
  color: var(--dark);
  background-color: #d9d9d9 !important;
}

.nvbrdrp .dropdown-item:hover {
  color: var(--white);
  background-color: var(--dark);
}

.nvbrdrp .dropdown-divider {
  margin: 0;
  border: 0;
}

.nvbr .dropdown-menu[data-bs-popper] {
  top: 55px;
  left: -80px;
  width: fit-content;
  padding: 20px;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.nvbrdrp {
  position: relative;
}

.nvbrdrp::before {
  content: '';
  position: absolute;
  top: -17px;
  left: 50%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 17px solid #d9d9d9;
}

.sign-in-tag {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-in-tag a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 3px;
  text-transform: none !important;
}

/* Popover */

.icon-wrapper::before {
  content: '';
  position: absolute;
  border: 1px solid white;
  top: 10px;
  left: 17px;
  transform: translate(-50%, -50%);
  width: 8px;
  height: 8px;
  background-color: var(--wine);
  border-radius: 50%;
}

.mobile-icon-wrapper::before {
  content: '';
  position: absolute;
  border: 1px solid white;
  top: 10px;
  left: 8px;
  transform: translate(-50%, -50%);
  width: 9px;
  height: 9px;
  background-color: var(--wine);
  border-radius: 50%;
}

.strUsername {
  font-size: 11px;
  line-height: 6px;
  font-weight: 300;
  color: var(--wine);
}

.nvbr .list-group-item {
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  word-wrap: break-word;
}

.pw {
  position: absolute;
  left: 0px;
  transform: translate3d(82.4px, 0px, 0px);
}

.pw {
  position: relative;
  padding: 0px;
  background: var(--white);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.pw:before {
  content: '';
  position: absolute;
  rotate: 180deg;
  top: -15px;
  left: 0%;
  border-style: solid;
  border-width: 15px 15px 0;
  border-color: var(--dark) transparent;
  display: block;
  width: 0;
  z-index: 0;
}

/*********************************************** Sticky Header Hot Fix ********************************************/

.item {
  box-sizing: border-box;
  height: auto;
  padding: 15px;
  position: relative;
}

.brand-subcat {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family), sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  height: 40px;
}

.brand-subcat.selected {
  border-bottom: 2px solid #77353b;
}

.brand-allsubcat {
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  padding: 15px 0px;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  font-weight: 400;
}

.brandImgList {
  display: grid;
  grid-template-columns: repeat(auto-fill, 167px);
  grid-column-gap: 14px;
  grid-row-gap: 20px;
  justify-content: center;
}

.brandImg {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  height: 80px;
}

.brandImg > img {
  max-height: 55px;
  width: 118px;
  object-fit: contain;
}

/*********************************************** Sticky Header Hot Fix ********************************************/

@media screen and (max-width: 1200px) and (min-width: 992px) {
  .brand img {
    height: 40px;
    width: 245px;
    margin-right: -5px;
  }

  .headicon {
    width: 25px;
  }
}

@media (min-width: 991px) {
  .navList {
    margin-bottom: -25px;
  }
}

@media (max-width: 991px) {
  @keyframes slide-infinite {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .wrapper p {
    margin-top: -3px;
  }

  .wrapper {
    margin-left: 47px;
    margin-top: -33px;
  }
}

@media screen and (max-width: 376px) {
}

@media screen and (max-width: 320px) {
  .m-exclusive {
    font-size: 8px;
  }
}

/* Mobile Header */
.burgermenu {
  width: 24px;
  height: 18px;
  cursor: pointer;
}

.m-nvbrdrp {
  padding: 0 !important;
  z-index: 1030 !important;
  left: 0;
  position: absolute;
}

.m-nvbrdrp::before {
  content: '';
  position: absolute;
  top: -17px;
  left: 25%;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 15px solid transparent;
  border-bottom: 17px solid #d9d9d9;
}

.m-nvbrdrp .dropdown-item {
  width: 181px;
  padding: 15px;
  text-align: left;
  font-family: var(--font-family);
  font-size: 14px;
  background: var(--dark);
  color: var(--white);
  border: 10px solid #ededed;
  text-transform: none;
}

.m-nvbrdrp .dropdown-item:hover {
  color: var(--dark);
  background-color: transparent;
}

.m-myacc {
  font-size: 18px !important;
  font-weight: 700;
  background-color: #d9d9d9 !important;
  box-shadow: 0px 4px 5px 0.1px rgb(0 0 0 / 30%);
}

.m-myacc:hover {
  color: var(--dark);
  background-color: #d9d9d9 !important;
}

.nf-img {
  width: 100%;
}

@media (max-width: 992px) {
  .header {
    display: none;
  }
}

.wishlist-pop-over-arrow {
  left: 288px !important;
}

.wish-mini {
  max-height: 50px;
}

.brand-name-wishlist-drpdwn {
  margin-top: -17px;
}

.brand-name-wishlist-drpdwn-conf {
  margin-top: -15px;
}

.wishdrop-inr {
  font-size: 10px;
  line-height: 9px;
}

.simpadd {
  padding-top: 10px;
}

.wishdrop-more {
  color: var(--dark);
  font-size: 8px;
  font-weight: 500;
}

.strike-through-text {
  text-decoration: line-through;
}

.strike-through-text-currency::before {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 1px;
  right: 0;
  height: 1px;
  width: 100%;
  background-color: #787878;
  /* Adjust the color as needed */
}

.wish-drp {
  line-height: 0.8rem;
}

.cartLoginPopup .modalClose {
  background-color: transparent;
  border: none;
}

.cartLoginPopup .modal-content {
  width: 100%;
  max-width: 760px;
  padding: 20px;
  font-family: var(--font-family);
}

.cartLoginPopup .modal-header {
  padding: 0;
  padding-bottom: 10px;
}

.cartLoginPopup .modal-title {
  font-size: 20px;
  line-height: 24px;
  text-transform: uppercase;
}

.cartLoginPopup .loginTile {
  border-radius: 6px;
  background: #f5f5f5;
  padding: 20px;
  width: 49%;
}

.cartLoginPopup .loginTile img {
  border-radius: 6px;
}

.loginTile button {
  color: black;
}

.cartLoginPopup .loginTile h2 {
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.cartLoginPopup .loginTile p {
  color: #787878;
  text-align: center;
  font-size: 14px;
  font-weight: 300;
  line-height: 21px;
  margin-bottom: 50px;
}

.cartLoginPopup .loginTile button {
  width: 100%;
  background: transparent;
  border-radius: 6px;
  border: 1px solid var(--dark);
  font-size: 14px;
  line-height: 11px;
  text-transform: uppercase;
  padding-top: 17px;
  padding-bottom: 17px;
}

.cartLoginPopupMobile h2 {
  font-size: 3vw !important;
}

.cartLoginPopupMobile .loginTile {
  display: flex;
  border-radius: 6px;
  background: #f5f5f5;
  padding: 20px;
  width: 49%;
  flex-flow: inherit;
}

.cartLoginPopupMobile .loginTile button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 12px;
}

.cartLoginPopupMobile .third-section {
  width: 100%;
}

.cartLoginPopupMobile .loginTile {
  display: flex !important;
  flex-flow: inherit !important;
  align-items: baseline !important;
}

@media screen and (max-width: 410px) {
  .cartLoginPopupMobile .loginTile button {
    position: absolute;
    font-size: 10px;
    bottom: 29px;
    max-width: 126px;
    margin-left: -5px;
  }
}

@media screen and (max-width: 370px) {
  .cartLoginPopupMobile .loginTile button {
    position: absolute;
    font-size: 9px;
    bottom: 29px;
    max-width: 120px;
  }

  .second-section {
    min-height: 148px;
  }
}

@media screen and (max-width: 350px) {
  .cartLoginPopupMobile {
    zoom: 50%;
  }

  .cartLoginPopupMobile .loginTile button {
    margin-left: 39px;
  }

  .cartLoginPopup .loginTile h2 {
    font-size: 15px !important;
  }
}

header .plp-infoHolder-ConfigOptions-colorImage {
  border-radius: 10px;
  height: 10px;
  width: 10px;
}

.wsh-pr {
  padding-right: 2px;
}
