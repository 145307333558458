.skeleton-deep {
  background-size: 200% 100%;
  -webkit-animation: 1.5s shine linear infinite;
  animation: 1.5s shine linear infinite;
}

@-webkit-keyframes shine {
  to {
    background-position-x: -200%;
  }
}

@keyframes shine {
  to {
    background-position-x: -200%;
  }
}

.mobPdpThumb {
  max-width: 80px;
  max-height: 80px;
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .mob-show {
    display: block !important;
  }

  .myorder-skeleton {
    min-height: 500px !important;
  }
}

@media screen and (min-width: 1024px) {
  .mobileHomeSkeleton {
    margin-left: 40px;
    margin-right: 40px;
  }

  .mobileHomeSkeleton-arrow {
    display: block !important;
  }
}